<template>
  <div class="app-container">
    <!-- <div class="loading-container" v-show="show">
      <div id="loading" class="loading-spinner"></div>
    </div> -->
    <div
      class="loading-container"
      v-show="show"
      style="position: absolute; top: 2%"
    >
      <div id="loading" class="loading-spinner"></div>
    </div>

    <div class="jieshao">
      <!-- <img v-if="erweima != ''" class="jieshao_img" :src="erweima" />
      <div v-else class="jieshao_img"></div> -->
      <img class="jieshao_img" :src="erweima" @click="shuaxin" />
      <div
        v-if="
          erweima == 'http://feioubang.com:8082/profile/upload/mohuerweeima.jpg'
        "
        class="jieshao_div"
        style="
          font-size: 14px;
          font-weight: 500;
          margin-top: 0px;
          position: absolute;
        "
        @click="shuaxin"
      >
        请刷新二维码
      </div>
      <img class="jieshao_img" src="@/assets/zidingyifengmian.png" />
      <div class="jieshao_div">---情景演示---</div>
      <div class="jieshao_divs">照片，在证明我们的回忆</div>
      <img class="jieshao_img" src="@/assets/shouye05.png" alt="" />
      <div class="jieshao_divs">莫让青春一笑而过</div>
      <img class="jieshao_img" src="@/assets/shouye02.png" alt="" />
      <div class="jieshao_divs">天下快意之事莫若友,快友之事莫若谈</div>
      <img class="jieshao_img" src="@/assets/shouye04.png" alt="" />
      <div class="jieshao_divs">同声自相应，同心自相知</div>
      <img class="jieshao_img" src="@/assets/shouye06.png" alt="" />
      <div class="jieshao_divs">三里清风三里路，步步风里步步你</div>
      <div class="jieshao_div" style="margin-bottom: 50px">
        —— 每一本都值得珍藏 ——
      </div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import {
  accessToken,
  qianming,
  tbluserreleforweixin,
  getfenxiangurl,
} from "@/api/app";
export default {
  data() {
    return {
      loading: false,
      configMap: {
        appId: "wxd6568a93cd257ade",
        timestamp: "",
        nonceStr: "",
        signature: "",
        jsapi_ticket: "",
      },
      appid: "wxd6568a93cd257ade",
      winUrl: "",
      wstitle: "",
      wsdesc: "",
      wslink: "",
      cs: {
        code: "",
      },
      url: "",
      access_token: "",
      openid: "",
      state: "",
      weixin: {
        accountsopenid: "",
        accountsopenidfather: "",
      },

      xiangurl: {
        openid: "",
      },
      erweima: "",
      mohuerweeima: "",
      showerweima: false,
      show: true,
    };
  },
  created() {
    this.isWXBrowser = this.isWXBrowser();
    // if (this.isWXBrowser) {
    // this.code = this.GetQueryString("code");

    this.cs.code = this.GetQueryString("code");
    this.state = this.GetQueryString("state");
    console.log("code=" + this.cs.code);
    
    if (this.cs.code != "" && this.cs.code != undefined) {
      this.getaccesstoke();

      setTimeout(() => {
        this.getgzhqmxx();
      }, 1000);

      // 首先判断OPENID是否为空
      if (this.openid != "") {
        this.erweima =
          "http://feioubang.com:8082/profile/upload/mohuerweeima.jpg";
        setTimeout(() => {
          this.getfenxiangurl();
        }, 1000);
      } else {
        this.erweima =
          "http://feioubang.com:8082/profile/upload/mohuerweeima.jpg";
        setTimeout(() => {
          this.getfenxiangurl();
        }, 1000);
      }
    } else {
      //var local = "http://dalianzhiyu.com/";
      var local = "http://feioubang.com/fenxiang/";
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        this.appid +
        "&redirect_uri=" +
        encodeURIComponent(local) +
        "&response_type=code&scope=snsapi_base&state=" +
        this.state +
        "#wechat_redirect";

      // console.log("window.location.href=" + window.location.href);
    }

    // }

    console.log(this.configMap);
  },

  mounted() {},

  methods: {
    //判断是否是微信内置的浏览器
    isWXBrowser() {
      return (
        String(navigator.userAgent.toLowerCase().match(/MicroMessenger/i)) ===
        "micromessenger"
      );
    },

    getaccesstoke() {
      console.log("传参=" + this.cs.code);
      accessToken(this.cs).then((response) => {
        console.log(response);
        this.access_token = response.data.access_token;
        this.openid = response.data.openid;
        // 0818
        this.xiangurl.openid = this.openid;
        //this.wslink = "http://dalianzhiyu.com/?state="+this.openid;
        this.wslink = "http://feioubang.com/fenxiang/?state=" + this.openid;
        this.getstate();
      });
    },
    //  获取公众号签名信息
    getgzhqmxx() {
      this.winUrl = location;
      console.log("传参url=" + this.winUrl);
      qianming(this.winUrl).then((response) => {
        console.log(response);
        this.configMap.jsapi_ticket = response.data.jsapi_ticket;
        this.configMap.nonceStr = response.data.nonceStr;
        this.configMap.signature = response.data.signature;
        this.configMap.timestamp = response.data.timestamp;

        this.zhuanfa();
      });
    },

    zhuanfa() {
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来
        appId: this.configMap.appId, // 必填，公众号的唯一标识
        timestamp: this.configMap.timestamp, // 必填，生成签名的时间戳
        nonceStr: this.configMap.nonceStr, // 必填，生成签名的随机串
        signature: this.configMap.signature, // 必填，签名，见附录1
        jsApiList: [
          // "updateAppMessageShareData",
          "onMenuShareTimeline", //分享朋友圈接口
          "onMenuShareAppMessage", //分享给朋友接口
          "onMenuShareQQ", //分享到QQ
          "onMenuShareWeibo", //微信到腾讯微博
        ], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      });

      wx.ready(() => {
        (this.wstitle = "飞欧照片书"),
          (this.wsdesc = ""),
          // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。

          // 分享给朋友
          wx.onMenuShareAppMessage({
            title: this.wstitle,
            desc: this.wsdesc,
            link: this.wslink, //分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            // imgUrl: wsimg,//分享图标
            success: function () {
              // 用户点击了分享后执行的回调函数
              // alert("分享成功了,朋友");
            },
            cancel: function () {
              // 用户取消了分享后执行的回调函数
              // alert("分享失败");
            },
          });

        // 分享朋友圈接口
        wx.onMenuShareTimeline({
          title: this.wstitle,
          desc: this.wsdesc,
          link: this.wslink, //分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          // imgUrl: wsimg,//分享图标
          success: function () {
            // 用户点击了分享后执行的回调函数
            // alert("朋友圈分享成功了!");
          },
          cancel: function () {
            // 用户取消了分享后执行的回调函数
            // alert("朋友圈分享失败");
          },
        });

        // 分享到QQ
        wx.onMenuShareQQ({
          title: this.wstitle,
          desc: this.wsdesc,
          link: this.wslink,
          // imgUrl: wsimg,
          success: function () {
            // alert("分享成功");
          },
          cancel: function () {},
        });

        // 微信到腾讯微博
        wx.onMenuShareWeibo({
          title: this.wstitle,
          desc: this.wsdesc,
          link: this.wslink,
          // imgUrl: wsimg,
          success: function () {
            // alert("分享成功");
          },
          cancel: function () {},
        });

        // 分享到QQ空间
        wx.onMenuShareQZone({
          title: this.wstitle,
          desc: this.wsdesc,
          link: this.wslink,
          // imgUrl: wsimg,
          success: function () {
            // alert("分享成功");
          },
          cancel: function () {},
        });
      });
    },

    GetQueryString(name) {
      console.log("name=" + name);
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配
      var context = "";
      if (r != null) context = decodeURIComponent(r[2]);
      reg = null;
      r = null;
      console.log("context=" + context);
      return context == null || context == "" || context == "undefined"
        ? ""
        : context;
    },

    getstate() {
      if (this.state != "" && this.state != undefined) {
        this.weixin.accountsopenid = this.openid;
        this.weixin.accountsopenidfather = this.state;
        tbluserreleforweixin(this.weixin).then((response) => {
          console.log(response);
          // alert
        });
      }
    },

    getfenxiangurl() {
      // 当openid不为空时获取二维码
      if (this.xiangurl.openid != "") {
        getfenxiangurl(this.xiangurl).then((response) => {
          console.log("this.xiangurl", response);
          // this.mohuerweeima = '';
          this.erweima = response.data.strRe;
          this.showerweima = true;
          var loading = document.getElementById("loading");
          loading.style.display = "none";
          this.show = false;
        });
      } else {
        this.erweima =
          "http://feioubang.com:8082/profile/upload/mohuerweeima.jpg";
        // this.erweima = '';
        var loading = document.getElementById("loading");
        loading.style.display = "none";
        this.show = false;
      }
    },

    // 刷新二维码
    shuaxin() {
      console.log("刷新二维码");
      this.getfenxiangurl();

      console.log("刷新二维码11111");
    },
  },
};
</script>
<style>
.app-container {
  width: 99%;
  height: 98%;
  position: absolute;
  left: 0%;
  box-sizing: border-box;
}
.jieshao {
  width: 100%;
  height: 100%;
  position: absolute;
  box-sizing: border-box;
  /* overflow-y: auto; */
}
.jieshao_img {
  width: 100%;
  height: 400px;
}
.jieshao_div {
  font-size: 24px;
  font-family: 1000;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}
.jieshao_divs {
  font-size: 16px;
  font-family: 1000;
  width: 100%;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #868686;
}

.loading-container {
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 30%;
  height: 30%;
  margin-left: 45%;
  padding-top: 15%;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* loading背景的透明度和颜色 */
  /* background-color: rgba(0, 0, 0, 0);  */
  /* loading的层级 */
  /* z-index: 0; */
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 4px solid #fff; /* loading旋转圆圈的颜色和粗细 */
  border-top-color: #c9c9c9; /* loading旋转圆圈的颜色 */
  animation: spin 1s linear infinite; /* loading旋转动画的设置 */
}

/* .loading-text {
  margin-top: 10px;
  color: #fff; 
} */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
