//该文件专门创建整个应用的路由器
import VueRouter from 'vue-router'

import Vue from 'vue'

//引入组件
import Fenxiang from '../view/Fenxiang.vue'
import Youhuiquan from '../view/Youhuiquan.vue'

// 将组件挂载在实例上
Vue.use(VueRouter)

//创建并暴露一个路由器
const router = new VueRouter({
  routes: [{
      path: '/',
      component: Fenxiang
    },
    {
      path: '/fenxiang',
      component: Fenxiang
    },
    {
      path: '/youhuiquan',
      component: Youhuiquan
    },
  ],
  mode: 'history'
})

// 向外抛出路由组件
export default router