import request from '@/utils/request'

//  获取acctoken和openid
export function accessToken(data) {
  return request({
    //url: 'http://dalianzhiyu.com:8082/weixin/gongzhonghao/access_token',
    url: 'http://feioubang.com:8082/weixin/gongzhonghao/access_token',
    method: 'post',
    data:data
  })
}

// 获取公众号签名信息
export function qianming(data) {
  return request({
    //url: 'http://dalianzhiyu.com:8082/weixin/gongzhonghao/qianming',
    url: 'http://feioubang.com:8082/weixin/gongzhonghao/qianming',
    method: 'post',
    data:data
  })
}


// 获取公众号签名信息
export function tbluserreleforweixin(data) {
  return request({
    url: 'https://feiou.feioubang.com:8081/wechat/team/tbluserreleforweixin',
    method: 'post',
    data:data
  })
}

// 获取二维码
export function getfenxiangurl(data) {
  return request({
    url: 'http://feioubang.com:8082/weixin/gongzhonghao/getfenxiangurl',
    method: 'post',
    data:data
  })
}

// 查询优惠券
export function getpanicbuyinglist(data) {
  return request({
    url: 'https://feiou.feioubang.com:8081/wechat/team/getpanicbuyinglist',
    method: 'post',
    data:data
  })
}

// 领取优惠券
export function applygetcoupon(data) {
  return request({
    url: 'https://feiou.feioubang.com:8081/wechat/team/applygetcoupon',
    method: 'post',
    data: data
  })
}


