<template>
  <div class="zt">
    <div v-if="list != ''">
      <div class="yhq" style="margin-top: 10px" v-for="item in list">
        <div class="yhqone">
        <span class="scale" style="font-size: 26px">{{ item.scale }}<span style="font-size: 26px">折</span></span>
        <span class="endtime">有效日期至：{{ item.endtime }}</span>
        </div>

        <div class="fgx" @click="lingqu(item)">
          <div class="lq" style="margin-top: 12px;">领</div>
          <div class="lq">取</div>
          <!-- <div class="lq">领取</div> -->
        </div>
      </div>
      <div class="yhqgz" style="display: flex;flex-direction: column;">
        <span class="yhqgz-span" style="text-align: center;color: #000;margin-top: 10px;margin-left: 0%;font-size: 12px;">
          每日11: 00、15: 00、20: 00定时发放优惠卷。
        </span>
        <span class="yhqgz-span" style="margin-left: 3%;margin-top: 8px;">
        亲：优惠券可以转赠给你的小伙伴
        </span>
        <span class="yhqgz-span" style="margin-left: 3%;">
        转赠方法：
        </span>
        <span class="yhqgz-span">
        1、点击‘我的卡券’
        </span>
        <span class="yhqgz-span">
        2、点击你要转赠的‘优惠券’
        </span>
        <span class="yhqgz-span">
        3、点击最下方‘转赠’按钮
        </span>
        <span class="yhqgz-span">
        4、输入接收优惠券的小伙伴手机号。
        </span>
        <span class="yhqgz-span" style="margin-left: 7%;margin-top: 0px;">
          注：一定是他（她）注册飞欧会员时用的手机号
        </span>
        <span class="yhqgz-span">
        5、点击下方按钮‘确认转赠’
        </span>
        <span style="padding-bottom: 10px;" class="yhqgz-span">
        6、转赠成功了，你的朋友在他的飞欧小程序-我的卡券里就能看这张优惠券，并能正常使用了。
        </span>
    </div>
    </div>

    <div v-else style="text-align: center; margin-top: 45%; color: #000">
      暂无优惠券
    </div>
  </div>
</template>
   
<script>
import {
  accessToken,
  getpanicbuyinglist,
  applygetcoupon,
} from "@/api/app";

export default {
  data() {
    return {
      list: [],
      //   点击领取参数
      dataform: {
        scale: "",
        buyingid: "",
        accountsopenid: "",
      },
      form: {}, //查询传参
      appid: "wxd6568a93cd257ade",
      cs: {
        code: "",
      },
      access_token: "",
      openid: "",
      state: "",
    };
  },
  created() {
    this.cs.code = this.GetQueryString("code");
    this.state = this.GetQueryString("state");
    console.log("code=" + this.cs.code);
    if (this.cs.code != "" && this.cs.code != undefined) {
        this.getlist();
      this.getaccesstoke();
    } else {
        this.getlist();
      var local = "http://feioubang.com/youhuiquan/";
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        this.appid +
        "&redirect_uri=" +
        encodeURIComponent(local) +
        "&response_type=code&scope=snsapi_base&state=" +
        this.state +
        "#wechat_redirect";
    }
  },
  methods: {
    GetQueryString(name) {
      console.log("name=" + name);
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      //获取url中"?"符后的字符串并正则匹配
      var r = window.location.search.substr(1).match(reg);
      var context = "";
      if (r != null) context = decodeURIComponent(r[2]);
      reg = null;
      r = null;
      console.log("context=" + context);
      return context == null || context == "" || context == "undefined"
        ? ""
        : context;
    },

    // 获取openid
    getaccesstoke() {
      console.log("传参=" + this.cs.code);
      accessToken(this.cs).then((response) => {
        console.log("openid", response);
        this.access_token = response.data.access_token;
        this.openid = response.data.openid;
        this.dataform.accountsopenid = response.data.openid;
      });
    },

    // 查询优惠券
    getlist() {
      getpanicbuyinglist(this.form).then((response) => {
        console.log("查询优惠券", response.rows);
        this.list = response.rows;
      });
    },

    // 领取优惠券
    lingqu(item) {
      this.dataform.buyingid = item.buyingid;
      this.dataform.scale = item.scale;
      applygetcoupon(this.dataform).then((response) => {
        if (response.code == 200) {
          this.$message({
            // type: "success",
            message: response.msg,
            center: true,
          });
          this.getlist();
        } else {
          this.$message({
            // type: "error",
            message: response.msg,
            center: true,
          });
          this.getlist();
        }
      });
    },
  },
};
</script>
   
<style>
#app {
  position: absolute;
  width: 100%;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  margin-top: -8px;
  margin-left: -8px;
}
.zt {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 0px solid #ebbaff;
  background: #ebbaff;
  box-sizing: border-box;
  overflow-y:auto;
}
.yhq {
  width: 90%;
  height: 70px;
  position: relative;
  border: 1px solid #ffffff;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  left: 5%;
  margin-bottom: 10px;
}
.yhq .yhqone .scale {
  position: absolute;
  margin-top: 10px;
  color: #bb00bb;
  width: 285px;
  text-align: center;
  font-weight: 550;
}

.yhq .yhqone .endtime {
  position: absolute;
  margin-top: 40px;
  color: #8d8d8d;
  width: 285px;
  text-align: center;
  font-size: 15px;
}

.yhq .yhqone {
  position: absolute;
  width: calc(100% - 60px);
  height: 70px;
  left: 0px;
  top: -1px;
  /* background: #eed9ee; */
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.yhq .fgx {
  position: absolute;
  width: 60px;
  height: 70px;
  right: -1px;
  top: -1px;
  background: #bb00bb;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  /* display: flex;
  flex-flow: column; */
}

.yhq .fgx .lq {
  display: flex;
  flex-flow: column;
  width: 60px;
  font-size: 21px;
  color: #fff;
  text-align: center;
  font-weight: 550;
  -webkit-text-size-adjust: none;
}

.el-message {
  top: 40% !important;  
}

.yhqgz {
  width: 90%;
  position: relative;
  border: 1px solid #000000;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  left: 5%;
  line-height: auto;
}

.yhqgz-span{
  color: #ff0303;
  font-size: 12px;
  margin: 1px 10px 0px 0px;
  margin-left: 5%;

}

</style>